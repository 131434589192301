html {
  min-height: 100vh;
  min-width: 100vw;
  margin: 0px;
  padding: 0px;
  display: flex;
}

body {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex: 1;
}

#root {
  display: flex;
  flex: 1;
}

.App {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  min-width: 100%;
  text-align: center;
}

#workspace {
  flex: 1;
}

#navbar {
  flex: 1;
  justify-content: space-between;
}

#updates-accordion {
  flex: 1;
  flex-direction: column;
}

.updates-graph {
  flex: 1;
}

.p-toggleable-content {
  display: flex;
  flex: 1;
}

.p-accordion-content{
  display: flex;
  flex: 1;
  flex-direction: column;
} 

.p-panel-content {
  display: flex;
  flex: 1;
}

.p-sidebar-bottom.p-sidebar-lg {
    height: 80vh !important;
    display: flex;
    flex-direction: column;
}

.p-sidebar-content {
    display: flex;
    flex: 1;
}

.p-tag.p-tag-secondary {
    background-color: #607D8B;
    color: #ffffff;
}

/* SVG */
.node rect {
  stroke: #333;
  fill: #fff;
}

.barrier rect {
  fill: hsl(204, 71%, 53%);
}

.deadend rect {
  fill: hsl(348, 86%, 61%);
}

.rollout rect {
  fill: hsl(171, 100%, 41%);
}

.edgePath path {
  stroke: #333;
  fill: #333;
  stroke-width: 1.5px;
}
